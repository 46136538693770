import { FC, useState } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useDispatch } from 'react-redux';
import { setCredentials } from "slices/authSlice";
import {stringIsEmpty} from "../../utils/validation";


export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();


  const handleLogin = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const savedUser = userCredential.user;
        dispatch(setCredentials(savedUser));
        setLoading(false);
        navigate("/");
      })
      .catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
          toast.error(t('emailAlreadyInUse'), { position: "top-center", id: "nc-product-notify", duration: 3000 });
        }
        if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
          toast.error(t('emailOrPasswordIncorrect'), { position: "top-center", id: "nc-product-notify", duration: 3000 });
        }
        console.log(error.code);
        setLoading(false);
      });
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>{t('signIn')} | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h6 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t('signIn')}
        </h6>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleLogin} method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('email')}
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t('password')}
              </span>
              <Input type="password"
                placeholder="*********"
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1" />
            </label>
            <ButtonPrimary loading={loading} type="submit" disabled={stringIsEmpty(email)||stringIsEmpty(password)}>{t('signIn')}</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {`${t('newUser')} ? `}
            <Link className="text-green-600" to="/signup">
              {t('createAnAccount')}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
