import Label from "components/Label/Label";
import React, {FC, useEffect} from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../firebase";
import {Cart} from "../../types/cart";
import {addToContactInfo} from "../../slices/cartSlice";

interface Props {
    isActive: boolean;
    onOpenActive: () => void;
    onCloseActive: () => void;
}

const ContactInfo: FC<Props> = ({isActive, onCloseActive, onOpenActive}) => {
    const {t} = useTranslation();
    const {userInfo} = useSelector((state: any) => state.auth);
    const cart = useSelector<any>(state => state.cart) as Cart;
    const contactInfo = cart.contactInfo;
    const dispatch = useDispatch();

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const {name, value} = e.target;
        dispatch(addToContactInfo({...contactInfo, [name]: value}));
    }

    useEffect(() => {
        if (!contactInfo) {
            getDoc(doc(db, "users", userInfo.uid)).then(docSnap => {
                if (docSnap.exists()) {
                    const userDoc = docSnap.data();
                    dispatch(addToContactInfo({
                        displayName: userDoc.displayName,
                        email: userDoc.email,
                        phoneNumber: userDoc.phoneNumber,
                    }));
                } else {
                    console.log("No such document!");
                }
            });
        }
    }, [userInfo.uid, contactInfo]);

    const renderAccount = () => {
        return (
            <div className="border border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden z-0">
                <div className="flex flex-col sm:flex-row items-start p-6 ">
          <span className="hidden sm:block">
            <svg
                className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.50998C8.71997 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
              />
              <path
                  d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
              />
              <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
              />
            </svg>
          </span>
                    <div className="sm:ml-8">
                        <h3 className=" text-slate-700 dark:text-slate-300 flex ">
                            <span className="uppercase tracking-tight">{t('contactInfo')}</span>
                            <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2.5"
                                stroke="currentColor"
                                className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100 "
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4.5 12.75l6 6 9-13.5"
                                />
                            </svg>
                        </h3>
                        <div className="font-semibold mt-1 text-sm">
                            <span className="">{contactInfo.displayName}</span>
                            <span className="ml-3 tracking-tighter">{contactInfo.phoneNumber}</span>
                        </div>
                    </div>
                    <ButtonSecondary
                        sizeClass="py-2 px-4 "
                        fontSize="text-sm font-medium"
                        className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
                        onClick={() => onOpenActive()}
                    >
                        {t('change')}
                    </ButtonSecondary>
                </div>
                <div
                    className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
                        isActive ? "block" : "hidden"
                    }`}
                >
                    <div className="max-w-lg">
                        <Label className="text-sm">{t('receiver')}</Label>
                        <Input className="mt-1.5" value={contactInfo.displayName ?? ''}
                               name="displayName"
                               onChange={handleInputChange}
                               type={"tel"}/>
                    </div>
                    <div className="max-w-lg">
                        <Label className="text-sm">{t('contactNumber')}</Label>
                        <Input className="mt-1.5" defaultValue={"+90 5xx xxx xx xx"}
                               value={contactInfo.phoneNumber ?? ''}
                               name="phoneNumber"
                               onChange={handleInputChange}
                               type={"tel"}/>
                    </div>
                    <div className="max-w-lg">
                        <Label className="text-sm">{t('email')}</Label>
                        <Input className="mt-1.5" type={"email"} value={contactInfo.email ?? ''} name="email"
                               onChange={handleInputChange}/>
                    </div>
                    <div className="flex flex-col sm:flex-row pt-6">
                        <ButtonPrimary
                            className="sm:!px-7 shadow-none"
                            onClick={() => onCloseActive()}
                        >
                            {t('saveAndContinue')}
                        </ButtonPrimary>
                        <ButtonSecondary
                            className="mt-3 sm:mt-0 sm:ml-3"
                            onClick={() => onCloseActive()}
                        >
                            {t('cancel')}
                        </ButtonSecondary>
                    </div>
                </div>
            </div>
        );
    };

    return renderAccount();
};

export default ContactInfo;
