import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Page} from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import PageSearch from "containers/PageSearch";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import AccountAddresses from "containers/AccountPage/AccountAddresses";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PageCollection from "containers/PageCollection";
import {Toaster} from "react-hot-toast";
import PrivateRoute from "components/PrivateRoute";
import PageDesignYourself from "../containers/PageDesignYourself/PageDesignYourself";

export const pages: Page[] = [
    {id: 1, path: "/", component: PageHome},
    {id: 2, path: "/home2", component: PageHome2},
    {id: 3, path: "/home3", component: PageHome3},
    //
    {id: 4, path: "/home-header-2", component: PageHome},
    {id: 6, path: "/product-detail/:id", component: ProductDetailPage},
    //
    {id: 7, path: "/page-collection", component: PageCollection},
    {id: 9, path: "/page-search", component: PageSearch},
    //
    {id: 10, path: "/design-it-yourself", component: PageDesignYourself},
    {id: 16, path: "/cart", component: CartPage},
    //
    {id: 18, path: "/blog", component: BlogPage},
    {id: 19, path: "/blog-single", component: BlogSingle},
    //
    {id: 20, path: "/contact", component: PageContact},
    {id: 21, path: "/about", component: PageAbout},
    {id: 22, path: "/signup", component: PageSignUp},
    {id: 23, path: "/login", component: PageLogin},
    {id: 24, path: "/subscription", component: PageSubcription},
    {id: 25, path: "/not-found", component: Page404},
];

const privatePages: Page[] = [
    {id: 10, path: "/account", component: AccountPage},
    {id: 11, path: "/account-savelists", component: AccountSavelists},
    {id: 12, path: "/account-change-password", component: AccountPass},
    {id: 13, path: "/account-billing", component: AccountBilling},
    {id: 14, path: "/account-my-order", component: AccountOrder},
    {id: 15, path: "/my-addresses", component: AccountAddresses},
    {id: 17, path: "/checkout", component: CheckoutPage},
];


const MyRoutes = () => {
    return (
        <BrowserRouter>
            <Toaster/>
            <ScrollToTop/>
            <SiteHeader/>
            <Routes>
                {pages.map(({id, component: Component, path}) => {
                    return <Route key={id} element={<Component/>} path={path}/>;
                })}
                <Route path="" element={<PrivateRoute/>}>
                    {privatePages.map(({id, component: Component, path}) => {
                        return <Route key={id} element={<Component/>} path={path}/>;
                    })}
                </Route>
                <Route element={<Page404/>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
};

export default MyRoutes;
