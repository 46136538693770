import {SocialType} from "shared/SocialsShare/SocialsShare";
import React, {FC, useEffect} from "react";
import facebook from "images/socials/facebook.svg";
import youtube from "images/socials/youtube.svg";
import whatsapp from "images/socials/whatsapp.svg";
import instagram from "images/socials/instagram.svg";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../firebase";

export interface SocialsList1Props {
    className?: string;
}

const SocialsList1: FC<SocialsList1Props> = ({className = "space-y-3"}) => {
    const [links, setLinks] = React.useState<SocialType[]>([]);
    useEffect(() => {
        const docRef = doc(db, 'general-settings', 'social-links');

        getDoc(docRef).then((doc) => {
            if (doc.exists()) {
                const data = doc.data();
                setLinks(
                    [
                        {name: "Facebook", icon: facebook, href: data.facebook},
                        {name: "Youtube", icon: youtube, href: data.youtube},
                        {name: "Whatsapp", icon: whatsapp, href: data.whatsapp},
                        {name: "Instagram", icon: instagram, href: data.instagram}
                    ]
                );
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }, []);
    const renderItem = (item: SocialType, index: number) => {
        return (
            item.href &&
            <a
                href={item.href}
                className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
                key={index}
            >
                <div className="flex-shrink-0 w-5 ">
                    <img src={item.icon} alt=""/>
                </div>
                <span className="hidden lg:block text-sm">{item.name}</span>
            </a>
        );
    };

    return (
        <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
            {links.map(renderItem)}
        </div>
    );
};

export default SocialsList1;
