import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import {useState} from "react";
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ContactInfo from "./ContactInfo";
import PaymentMethod from "./PaymentMethod";
import ShippingAddress from "./ShippingAddress";
import {useDispatch, useSelector} from "react-redux";
import {Cart} from "../../types/cart";
import {useTranslation} from "react-i18next";
import {removeFromCart} from "../../slices/cartSlice";
import {CartItem} from "../../types/cartItem";
import axios from "axios";


const CheckoutPage = () => {
    const [tabActive, setTabActive] = useState<
        "ContactInfo" | "ShippingAddress" | "PaymentMethod"
    >("ContactInfo");


    const cart = useSelector<any>(state => state.cart) as Cart;
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const removeFromCartHandler = async (uid: string) => {
        dispatch(removeFromCart(uid));
    }

    const handleScrollToEl = (id: string) => {
        const element = document.getElementById(id);
        setTimeout(() => {
            element?.scrollIntoView({behavior: "smooth"});
        }, 80);
    };

    const renderProduct = (item: CartItem, index: number) => {
        const {price, name, size} = item;

        return (
            <div key={index} className="relative flex py-7 first:pt-0 last:pb-0">
                <div className="relative h-36 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                    <img
                        src={item.images[0].downloadURL}
                        alt={name}
                        className="h-full w-full object-contain object-center"
                    />
                    <Link to="/product-detail" className="absolute inset-0"></Link>
                </div>

                <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
                    <div>
                        <div className="flex justify-between ">
                            <div className="flex-[1.5] ">
                                <h3 className="text-base font-semibold">
                                    <Link to="/product-detail">{name}</Link>
                                </h3>
                                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                                    <div className="flex items-center space-x-1.5">
                                        <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M7.01 18.0001L3 13.9901C1.66 12.6501 1.66 11.32 3 9.98004L9.68 3.30005L17.03 10.6501C17.4 11.0201 17.4 11.6201 17.03 11.9901L11.01 18.0101C9.69 19.3301 8.35 19.3301 7.01 18.0001Z"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M8.35 1.94995L9.69 3.28992"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M2.07 11.92L17.19 11.26"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M3 22H16"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M18.85 15C18.85 15 17 17.01 17 18.24C17 19.26 17.83 20.09 18.85 20.09C19.87 20.09 20.7 19.26 20.7 18.24C20.7 17.01 18.85 15 18.85 15Z"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <span>{`Black`}</span>
                                    </div>
                                    <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                                    <div className="flex items-center space-x-1.5">
                                        <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M21 9V3H15"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M3 15V21H9"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M21 3L13.5 10.5"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M10.5 13.5L3 21"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>

                                        <span>{size}</span>
                                    </div>
                                </div>

                                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                                    <select
                                        name="qty"
                                        id="qty"
                                        className="form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                    </select>
                                    <Prices
                                        contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                        price={price}
                                    />
                                </div>
                            </div>

                            <div className="hidden flex-1 sm:flex justify-end">
                                <Prices price={price} className="mt-0.5"/>
                            </div>
                        </div>
                    </div>

                    <div className="flex mt-auto pt-4 items-end justify-between text-sm">
                        <div className="hidden sm:block text-center relative">
                            <NcInputNumber className="relative z-10"/>
                        </div>

                        <button
                            onClick={() => removeFromCartHandler(item.uid ?? '')}
                            className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
                        >
                            <span>{t('remove')}</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const renderLeft = () => {
        return (
            <div className="space-y-8">
                <div id="ContactInfo" className="scroll-mt-24">
                    <ContactInfo
                        isActive={tabActive === "ContactInfo"}
                        onOpenActive={() => {
                            setTabActive("ContactInfo");
                            handleScrollToEl("ContactInfo");
                        }}
                        onCloseActive={() => {
                            setTabActive("ShippingAddress");
                            handleScrollToEl("ShippingAddress");
                        }}
                    />
                </div>

                <div id="ShippingAddress" className="scroll-mt-24">
                    <ShippingAddress
                        isActive={tabActive === "ShippingAddress"}
                        onOpenActive={() => {
                            setTabActive("ShippingAddress");
                            handleScrollToEl("ShippingAddress");
                        }}
                        onCloseActive={() => {
                            setTabActive("PaymentMethod");
                            handleScrollToEl("PaymentMethod");
                        }}
                    />
                </div>

                <div id="PaymentMethod" className="scroll-mt-24">
                    <PaymentMethod
                        isActive={tabActive === "PaymentMethod"}
                        onOpenActive={() => {
                            setTabActive("PaymentMethod");
                            handleScrollToEl("PaymentMethod");
                        }}
                        onCloseActive={() => setTabActive("PaymentMethod")}
                    />
                </div>
            </div>
        );
    };

    function checkOutOrder() {
        console.log('');
        /**
         const request = {
         locale: "tr",
         conversationId: '123456789',
         price: '100',
         paidPrice: '1.2',
         currency: "TRY",
         installment: '1',
         basketId: 'B67832',
         paymentChannel: "WEB",
         paymentGroup: "PRODUCT",
         paymentCard: {
         cardHolderName: 'John Doe',
         cardNumber: '5528790000000008',
         expireMonth: '12',
         expireYear: '2030',
         cvc: '123',
         registerCard: '0'
         },
         buyer: {
         id: 'BY789',
         name: 'John',
         surname: 'Doe',
         gsmNumber: '+905350000000',
         email: 'email@email.com',
         identityNumber: '74300864791',
         lastLoginDate: '2015-10-05 12:43:35',
         registrationDate: '2013-04-21 15:12:09',
         registrationAddress: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
         ip: '85.34.78.112',
         city: 'Istanbul',
         country: 'Turkey',
         zipCode: '34732'
         },
         shippingAddress: {
         contactName: 'Jane Doe',
         city: 'Istanbul',
         country: 'Turkey',
         address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
         zipCode: '34742'
         },
         billingAddress: {
         contactName: 'Jane Doe',
         city: 'Istanbul',
         country: 'Turkey',
         address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
         zipCode: '34742'
         },
         basketItems: [
         {
         id: 'BI101',
         name: 'Binocular',
         category1: 'Collectibles',
         category2: 'Accessories',
         itemType: "PHYSICAL",
         price: '0.3'
         },
         {
         id: 'BI102',
         name: 'Game code',
         category1: 'Game',
         category2: 'Online Game Items',
         itemType: "VIRTUAL",
         price: '0.5'
         },
         {
         id: 'BI103',
         name: 'Usb',
         category1: 'Electronics',
         category2: 'Usb / Cable',
         itemType: "PHYSICAL",
         price: '0.2'
         }
         ]
         };

         axios.post('http://localhost:8080/api/v1/payment', request)
         .then(res => console.log(res));*/
    }

    return (
        <div className="nc-CheckoutPage">
            <Helmet>
                <title>{t('checkout')} | {process.env.REACT_APP_NAME}</title>
            </Helmet>

            <main className="container py-16 lg:pb-28 lg:pt-20 ">
                <div className="mb-16">
                    <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
                        {t('checkout')}
                    </h2>
                    <div
                        className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
                        <Link to={"/"} className="">
                            {t('home')}
                        </Link>
                        <span className="text-xs mx-1 sm:mx-1.5">/</span>
                        <Link to={"/page-collection"} className="">
                            {t('products')}
                        </Link>
                        <span className="text-xs mx-1 sm:mx-1.5">/</span>
                        <span className="underline">{t('checkout')}</span>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row">
                    <div className="flex-1">{renderLeft()}</div>

                    <div
                        className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>

                    <div className="w-full lg:w-[36%] ">
                        <h3 className="text-lg font-semibold">{t('orderSummary')}</h3>
                        <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
                            {cart.cartItems.map(renderProduct)}
                        </div>

                        <div
                            className="mt-10 pt-6 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">
                            <div className="flex justify-between py-2.5">
                                <span>{t('shippingPrice')}</span>
                                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {cart.shippingPrice}
                </span>
                            </div>
                            <div
                                className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                                <span>{t('total')}</span>
                                <span>{cart.totalPrice}</span>
                            </div>
                        </div>
                        <ButtonPrimary onClick={checkOutOrder} className="mt-8 w-full">
                            {t('confirmOrder')}
                        </ButtonPrimary>
                        <div
                            className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
                            <p className="block relative pl-5">
                                <svg
                                    className="w-4 h-4 absolute -left-1 top-0.5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M12 8V13"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M11.9945 16H12.0035"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                Learn more{` `}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="##"
                                    className="text-slate-900 dark:text-slate-200 underline font-medium"
                                >
                                    Taxes
                                </a>
                                <span>
                  {` `}and{` `}
                </span>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="##"
                                    className="text-slate-900 dark:text-slate-200 underline font-medium"
                                >
                                    Shipping
                                </a>
                                {` `} infomation
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default CheckoutPage;
