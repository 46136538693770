import {Address} from "../../types/address";
import React, {FC} from "react";
import Radio from "../../shared/Radio/Radio";
import {useDispatch, useSelector} from "react-redux";
import {Cart} from "../../types/cart";
import {addToShippingAddress} from "../../slices/cartSlice";


export interface CardAddressProps {
    address: Address;
}

const CardAddressSelect: FC<CardAddressProps> = ({address}) => {
    const cart = useSelector<any>(state => state.cart) as Cart;
    const selectedAddress = cart.shippingAddress;
    const dispatch = useDispatch();

    const getAddressText = () => {
        return address.address.length > 30 ? address.address.substring(0, 30) + '...' : address.address;
    }
    const setSelectedAddress = () => {
        dispatch(addToShippingAddress(address));
    }
    return (<div key={address.id}
                 className="h-full relative px-6 py-8 rounded-3xl border-2
                 flex flex-col overflow-hidden border-neutral-200 dark:border-neutral-700 mt-2"
    >
        <div className="mb-8">
            <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 font-medium">
                <Radio
                    id={address.id}
                    key={address.id}
                    textClassName="text-xs"
                    sizeClassName="w-4 h-4"
                    name="selectedAddress"
                    defaultChecked={selectedAddress.id === address.id}
                    label={address.title}
                    onChange={setSelectedAddress}
                />
            </h3>
        </div>
        <nav className="space-y-4 mb-1">
            <li className="flex items-center">
                                <span className="text-xs text-neutral-700 dark:text-neutral-300">
                                    {getAddressText()}
                                </span>

            </li>
            <li className="flex items-center">
                                <span className="text-xs text-neutral-700 dark:text-neutral-700">
                                    {address.district + ' / ' + address.city}
                                </span>
            </li>
        </nav>
    </div>);
}

export default CardAddressSelect;