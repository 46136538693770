import Label from "components/Label/Label";
import React, {FC, useEffect, useState} from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import {useTranslation} from "react-i18next";
import {Address} from "../../types/address";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../firebase";
import {useSelector} from "react-redux";
import CardAddressSelect from "../../components/CardAddress/CardAddressSelect";
import Loader from "../../components/Loader";
import ButtonThird from "../../shared/Button/ButtonThird";

interface Props {
    isActive: boolean;
    onCloseActive: () => void;
    onOpenActive: () => void;
}

const ShippingAddress: FC<Props> = ({
                                        isActive,
                                        onCloseActive,
                                        onOpenActive,
                                    }) => {
    const {t} = useTranslation();
    const [selectedAddress, setSelectedAddress] = useState<Address>();
    const [userAddresses, setUserAddresses] = useState<Address[]>([]);
    const {userInfo} = useSelector((state: any) => state.auth);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAddresses = () => {
            try {
                getDoc(doc(db, "users-addresses", userInfo.uid)).then(docSnap => {
                    if (docSnap.exists()) {
                        const addressesDoc = docSnap.data();
                        const addresses = addressesDoc.addresses;
                        setUserAddresses(addresses);
                        handleDefaultSelection(addresses);
                    } else {
                        console.log("No such document!");
                    }
                });

            } catch (error) {
                console.error("Error fetching addresses:", error);
            }
        };

        const handleDefaultSelection = (addresses: Address[]) => {
            if (addresses.length > 1) {
                const defaultAddress = addresses.find(adr => adr.defaultAddress);
                setSelectedAddress(defaultAddress);
            } else if (addresses.length === 1) {
                setSelectedAddress(addresses[0]);
            }
            setLoading(false);
        };

        fetchAddresses();

    }, [userInfo.uid]);


    const renderShippingAddress = () => {
        return (
            <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
                <div className="p-6 flex flex-col sm:flex-row items-start">
          <span className="hidden sm:block">
            <svg
                className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
              />
              <path
                  d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
              />
              <path
                  d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
              />
              <path
                  d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
              />
              <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
              />
            </svg>
          </span>

                    <div className="sm:ml-8">
                        <h3 className=" text-slate-700 dark:text-slate-300 flex ">
                            <span className="uppercase">{t('shippingAddress')}</span>
                            <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2.5"
                                stroke="currentColor"
                                className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4.5 12.75l6 6 9-13.5"
                                />
                            </svg>
                        </h3>
                        <div className="font-semibold mt-1 text-sm">
              <span className="">
                {selectedAddress?.address}
              </span>
                        </div>
                    </div>
                    <ButtonSecondary
                        sizeClass="py-2 px-4 "
                        fontSize="text-sm font-medium"
                        className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
                        onClick={onOpenActive}
                    >
                        {t('change')}
                    </ButtonSecondary>
                </div>
                <div
                    className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
                        isActive ? "block" : "hidden"
                    }`}
                >
                    <Label>{t('shippingAddress')}</Label>
                    <div className="grid lg:grid-cols-2 gap-1 xl:gap-3 mt-auto">
                        {userAddresses.map(address => (
                            <CardAddressSelect key={address.id} address={address}/>
                        ))}
                    </div>
                    <div className="flex flex-col sm:flex-row pt-6">
                        <ButtonPrimary
                            className="sm:!px-7 shadow-none"
                            onClick={onCloseActive}
                        >
                            {t('saveAndContinue')}
                        </ButtonPrimary>
                        <ButtonSecondary
                            className="mt-3 sm:mt-0 sm:ml-3"
                            onClick={onCloseActive}
                        >
                            {t('cancel')}
                        </ButtonSecondary>
                        <ButtonThird
                            className="mt-3 sm:mt-0 sm:ml-3"
                            onClick={() => window.open('/my-addresses', '_blank')}>
                            {t('addAddress')} +
                        </ButtonThird>
                    </div>
                </div>
            </div>
        );
    };
    return loading ? <Loader/> : renderShippingAddress();
};

export default ShippingAddress;
