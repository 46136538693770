import { createSlice } from '@reduxjs/toolkit';

const userStr = localStorage.getItem('userInfo');
const initialState = {
  userInfo: userStr ? JSON.parse(userStr) : null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.userInfo = { ...action.payload };
      localStorage.setItem('userInfo', JSON.stringify({ ...action.payload }));
    },
    logout: (state) => {
      state.userInfo = null;
      localStorage.removeItem('userInfo');
    },
    setUserInfo: (state, action) => {
      state.userInfo = { ...state.userInfo, ...action.payload };
      localStorage.setItem(
        'userInfo',
        JSON.stringify({ ...action.payload, ...state.userInfo })
      );
    },
  },
});

export const { setCredentials, logout, setUserInfo } = authSlice.actions;

export default authSlice.reducer;
