import React, {FC} from "react";
import {Link} from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import {ArrowsPointingOutIcon} from "@heroicons/react/24/outline";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import BagIcon from "./BagIcon";
import toast from "react-hot-toast";
import {Transition} from "@headlessui/react";
import ModalQuickView from "./ModalQuickView";
import {useTranslation} from "react-i18next";
import {Product} from "../types/product";
import {useDispatch} from "react-redux";
import {addToCart} from "../slices/cartSlice";

export interface ProductCardProps {
    className?: string;
    data?: Product;
    isLiked?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({
                                               className = "",
                                               data = {
                                                   category: "",
                                                   comparePrice: 0,
                                                   cost: 0,
                                                   description: "",
                                                   height: "",
                                                   heightUnit: "",
                                                   images: [],
                                                   name: "",
                                                   price: 0,
                                                   shippingFees: 0,
                                                   tags: [],
                                                   taxRate: 0,
                                                   uid: null,
                                                   variants: [],
                                                   weight: "",
                                                   weightUnit: "",
                                                   width: "",
                                                   widthUnit: "",
                                                   sizes: []
                                               },
                                               isLiked,
                                           }) => {

    const [variantActive, setVariantActive] = React.useState(data.uid);
    const [showModalQuickView, setShowModalQuickView] = React.useState(false);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const notifyAddToCart = () => {
        dispatch(addToCart({...data, qty: 1, size: data.sizes[0]}));
        toast.custom(
            (toast) => (
                <Transition
                    appear
                    show={toast.visible}
                    className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                    enter="transition-all duration-150"
                    enterFrom="opacity-0 translate-x-20"
                    enterTo="opacity-100 translate-x-0"
                    leave="transition-all duration-150"
                    leaveFrom="opacity-100 translate-x-0"
                    leaveTo="opacity-0 translate-x-20"
                >
                    <p className="block text-base font-semibold leading-none">
                        {t('addedToCart')}!
                    </p>
                    <div className="border-t border-slate-200 dark:border-slate-700 my-4"/>
                    {renderProductCartOnNotify()}
                </Transition>
            ),
            {position: "top-right", id: "nc-product-notify", duration: 3000}
        );
    };

    const renderProductCartOnNotify = () => {
        return (
            <div className="flex ">
                <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                    <img
                        src={data.images[0].downloadURL}
                        alt={data.name}
                        className="h-full w-full object-cover object-center"
                    />
                </div>

                <div className="ml-4 flex flex-1 flex-col">
                    <div>
                        <div className="flex justify-between ">
                            <div>
                                <h3 className="text-base font-medium ">{data.name}</h3>
                                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  <span>
                    {data?.variants.find(v => v.uid === variantActive)?.variant ?? ''}
                  </span>
                                    <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                                    <span>{data.sizes[0]}</span>
                                </p>
                            </div>
                            <Prices price={data.price} className="mt-0.5"/>
                        </div>
                    </div>
                    <div className="flex flex-1 items-end justify-between text-sm">
                        <p className="text-gray-500 dark:text-slate-400">{t('quantity')} +1</p>

                        <div className="flex">
                            <Link
                                to={"/cart"}
                                className="font-medium text-primary-6000 dark:text-primary-500 "
                            >
                                {t('viewCart')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderVariants = () => {
        if (!data.variants || !data.variants.length) {
            return null;
        }

        return (
            <div className="flex ">
                {data.variants.map((variant, index) => (
                    <div
                        key={index}
                        onClick={() => setVariantActive(variant.uid)}
                        className={`relative w-11 h-6 rounded-full overflow-hidden z-10 border cursor-pointer ${
                            variantActive === variant.uid
                                ? "border-black dark:border-slate-300"
                                : "border-transparent"
                        }`}
                        title={variant.variant}
                    >
                        <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
                            <img
                                src={variant.thumbnail}
                                alt="variant"
                                className="absolute w-full h-full object-cover"
                            />
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const renderGroupButtons = () => {
        return (
            <div
                className="absolute bottom-0 group-hover:bottom-4 inset-x-1 flex justify-center opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
                <ButtonPrimary
                    className="shadow-lg"
                    fontSize="text-xs"
                    sizeClass="py-2 px-4"
                    onClick={() => notifyAddToCart()}
                >
                    <BagIcon className="w-3.5 h-3.5 mb-0.5"/>
                    <span className="ml-1">{t('addToCart')}</span>
                </ButtonPrimary>
                <ButtonSecondary
                    className="ml-1.5 bg-white hover:!bg-gray-100 hover:text-slate-900 transition-colors shadow-lg"
                    fontSize="text-xs"
                    sizeClass="py-2 px-4"
                    onClick={() => setShowModalQuickView(true)}
                >
                    <ArrowsPointingOutIcon className="w-3.5 h-3.5"/>
                    <span className="ml-1">{t('quickView')}</span>
                </ButtonSecondary>
            </div>
        );
    };

    return (
        <>
            <div
                className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
                data-nc-id="ProductCard"
            >
                <Link to={`/product-detail/${data.uid}`} className="absolute inset-0"></Link>

                <div
                    className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
                    <Link to={`/product-detail/${data.uid}`} className="block">
                        <NcImage
                            containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
                            src={data.images[0].downloadURL}
                            className="object-cover w-full h-full drop-shadow-xl"
                        />
                    </Link>

                    {/*<ProductStatus status={data.status} />*/}

                    <LikeButton liked={isLiked} className="absolute top-3 right-3 z-10"/>

                    {renderGroupButtons()}
                </div>

                <div className="space-y-4 px-2.5 pt-5 pb-2.5">
                    {renderVariants()}

                    <div>
                        <h2
                            className={`nc-ProductCard__title text-base font-semibold transition-colors`}
                        >
                            {data.name.length > 25 ? data.name.slice(0, 25) + "..." : data.name}
                        </h2>
                    </div>

                    <div className="flex justify-between items-end ">
                        <Prices price={data.price}/>
                    </div>
                </div>
            </div>

            {/* QUICKVIEW */}
            <ModalQuickView
                show={showModalQuickView}
                product={data as Product}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
            />
        </>
    );
};

export default ProductCard;
