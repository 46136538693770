import React, {FC, useEffect, useState} from "react";

import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import Radio from "shared/Radio/Radio";
import {Category} from "../types/category";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../firebase";
import {useTranslation} from "react-i18next";
import Loader from "../components/Loader";

const DATA_colors = [
    {name: "white"},
    {name: "beige"},
    {name: "blue"},
    {name: "black"},
    {name: "brown"},
    {name: "green"},
    {name: "navy"}
];

const DATA_sizes = [
    {name: "XS"},
    {name: "S"},
    {name: "M"},
    {name: "L"},
    {name: "XL"},
    {name: "2XL"},
];

const DATA_sortOrderRadios = [
    {name: "mostPopular", id: "mostPopular"},
    {name: "bestRating", id: "bestRating"},
    {name: "newest", id: "newest"},
    {name: "lowToHigh", id: "price-low-to-high"},
    {name: "highToLow", id: "price-high-to-low"},
];

export interface SidebarFiltersProps {
    fetchProducts: Function;
    minPrice: number;
    maxPrice: number;
    selectedCategory?: string | null;
}

const SidebarFilters: FC<SidebarFiltersProps> = ({fetchProducts, minPrice, maxPrice, selectedCategory}) => {
    const [rangePrices, setRangePrices] = useState([0, 0]);
    const [categoriesState, setCategoriesState] = useState<string[]>([]);
    const [colorsState, setColorsState] = useState<string[]>([]);
    const [gendersState, setGendersState] = useState<string[]>([]);
    const [sizesState, setSizesState] = useState<string[]>([]);
    const [sortOrderStates, setSortOrderStates] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState<Category[]>([]);
    const {t} = useTranslation();
    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        setRangePrices([minPrice, maxPrice]);
        if (selectedCategory) {
            handleChangeCategories(true, selectedCategory);
        }
    }, [minPrice, maxPrice, selectedCategory]);
    const fetchCategories = async () => {
        setLoading(true);
        let categoriesTemp: Category[] = [];
        const querySnapshot = await getDocs(collection(db, "categories"));
        querySnapshot.forEach((doc) => {
            categoriesTemp.push(doc.data() as Category);
        });
        setCategories(categoriesTemp);
        setLoading(false);
    }
    //
    const handleChangeCategories = (checked: boolean, name: string) => {
        if (checked) {
            setCategoriesState([...categoriesState, name]);
            fetchProducts({
                categories: [...categoriesState, name],
                sizes: sizesState,
                genders: gendersState
            });
        } else {
            setCategoriesState(categoriesState.filter((i) => i !== name));
            fetchProducts({
                categories: categoriesState.filter((i) => i !== name),
                sizes: sizesState, genders: gendersState
            });
        }

    };

    const handleChangeColors = (checked: boolean, name: string) => {
        if (checked) {
            setColorsState([...colorsState, name]);
            fetchProducts({categories: categoriesState, sizes: sizesState, colors: [...colorsState, name]});
        } else {
            setColorsState(colorsState.filter((i) => i !== name));
            fetchProducts({
                categories: categoriesState,
                sizes: sizesState,
                colors: colorsState.filter((i) => i !== name)
            });
        }
    };

    const handleChangeGender = (checked: boolean, name: string) => {
        if (checked) {
            setGendersState([...sizesState, name]);
            fetchProducts({
                categories: categoriesState,
                genders: [...gendersState, name],
                sizes: sizesState
            });
        } else {
            setGendersState(gendersState.filter((i) => i !== name));
            fetchProducts({
                categories: categoriesState,
                genders: gendersState.filter((i) => i !== name),
                sizes: sizesState
            });
        }
    }

    const handleChangeSizes = (checked: boolean, name: string) => {
        if (checked) {
            setSizesState([...sizesState, name]);
            fetchProducts({categories: categoriesState, sizes: [...sizesState, name]});
        } else {
            setSizesState(sizesState.filter((i) => i !== name));
            fetchProducts({categories: categoriesState, sizes: sizesState.filter((i) => i !== name)});
        }
    };

    const handlePriceRange = (value: number[]) => {
        setRangePrices(value);
        fetchProducts({categories: categoriesState, sizes: sizesState, priceRange: value});
    }

    const handleSortOrder = (value: string) => {
        setSortOrderStates(value);
        fetchProducts({categories: categoriesState, sizes: sizesState, priceRange: rangePrices, sortOrder: value});
    }

    const renderGenderTabs = () => {
        return (<div className="relative flex flex-col py-8 space-y-4">
            <h3 className="font-semibold mb-2.5">{t('gender')}</h3>
            <div className="">
                <Checkbox
                    sizeClassName="w-5 h-5"
                    labelClassName="text-sm font-normal"
                    name={'male'}
                    label={t('male')}
                    defaultChecked={false}
                    onChange={(checked) => handleChangeGender(checked, 'male')}
                />
            </div>
            <div className="">
                <Checkbox
                    sizeClassName="w-5 h-5"
                    labelClassName="text-sm font-normal"
                    name={'female'}
                    label={t('female')}
                    defaultChecked={false}
                    onChange={(checked) => handleChangeGender(checked, 'female')}
                />
            </div>
            <div className="">
                <Checkbox
                    sizeClassName="w-5 h-5"
                    labelClassName="text-sm font-normal"
                    name={'unisex'}
                    label={t('unisex')}
                    defaultChecked={false}
                    onChange={(checked) => handleChangeGender(checked, 'unisex')}
                />
            </div>
        </div>);
    }
    const renderTabsCategories = () => {
        return (<div className="relative flex flex-col pb-8 space-y-4">
            <h3 className="font-semibold mb-2.5">{t('categories')}</h3>
            {categories.map((item) => (
                <div key={item.uid} className="">
                    <Checkbox
                        name={item.name}
                        label={item.name}
                        defaultChecked={categoriesState.includes(item.name)}
                        sizeClassName="w-5 h-5"
                        labelClassName="text-sm font-normal"
                        onChange={(checked) => handleChangeCategories(checked, item.name)}
                    />
                </div>
            ))}
        </div>);
    };

    // OK
    const renderTabsColor = () => {
        return (
            <div className="relative flex flex-col py-8 space-y-4">
                <h3 className="font-semibold mb-2.5">{t('colors')}</h3>
                {DATA_colors.map((item) => (
                    <div key={item.name} className="">
                        <Checkbox
                            sizeClassName="w-5 h-5"
                            labelClassName="text-sm font-normal"
                            name={item.name}
                            label={t(item.name)}
                            defaultChecked={colorsState.includes(item.name)}
                            onChange={(checked) => handleChangeColors(checked, item.name)}
                        />
                    </div>
                ))}
            </div>
        );
    };

    // OK
    const renderTabsSize = () => {
        return (
            <div className="relative flex flex-col py-8 space-y-4">
                <h3 className="font-semibold mb-2.5">{t('sizes')}</h3>
                {DATA_sizes.map((item) => (
                    <div key={item.name} className="">
                        <Checkbox
                            name={item.name}
                            label={item.name}
                            defaultChecked={sizesState.includes(item.name)}
                            onChange={(checked) => handleChangeSizes(checked, item.name)}
                            sizeClassName="w-5 h-5"
                            labelClassName="text-sm font-normal"
                        />
                    </div>
                ))}
            </div>
        );
    };

    // OK
    const renderTabsPriceRage = () => {
        return (
            <div className="relative flex flex-col py-8 space-y-5 pr-3">
                <div className="space-y-5">
                    <span className="font-semibold">{t('priceRange')}</span>
                    <Slider
                        range
                        min={minPrice}
                        max={maxPrice}
                        step={1}
                        defaultValue={[minPrice, maxPrice]}
                        allowCross={false}
                        onChange={(_input: number | number[]) =>
                            handlePriceRange(_input as number[])
                        }
                    />
                </div>

                <div className="flex justify-between space-x-5">
                    <div>
                        <label
                            htmlFor="minPrice"
                            className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                            {t('minPrice')}
                        </label>
                        <div className="mt-1 relative rounded-md">
              <span
                  className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                ₺
              </span>
                            <input
                                type="text"
                                name="minPrice"
                                disabled
                                id="minPrice"
                                className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                                value={rangePrices[0]}
                            />
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="maxPrice"
                            className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                            {t('maxPrice')}
                        </label>
                        <div className="mt-1 relative rounded-md">
              <span
                  className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                ₺
              </span>
                            <input
                                type="text"
                                disabled
                                name="maxPrice"
                                id="maxPrice"
                                className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                                value={rangePrices[1]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // OK
    const renderTabsSortOrder = () => {
        return (
            <div className="relative flex flex-col py-8 space-y-4">
                <h3 className="font-semibold mb-2.5">{t('sort')}</h3>
                {DATA_sortOrderRadios.map((item) => (
                    <Radio
                        id={item.id}
                        key={item.id}
                        name="radioNameSort"
                        label={t(item.name)}
                        defaultChecked={sortOrderStates === item.id}
                        sizeClassName="w-5 h-5"
                        onChange={() => handleSortOrder(item.id)}
                        className="!text-sm"
                    />
                ))}
            </div>
        );
    };

    return (
        <div className="divide-y divide-slate-200 dark:divide-slate-700">
            {renderGenderTabs()}
            {loading ? <Loader/> : renderTabsCategories()}
            {renderTabsColor()}
            {renderTabsSize()}
            {renderTabsPriceRage()}
            {renderTabsSortOrder()}
        </div>
    );
};

export default SidebarFilters;
