import {useTranslation} from "react-i18next";
import CommonLayout from "./CommonLayout";
import citiesAndDistricts from "data/cities-and-districts.json";
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import Textarea from "shared/Textarea/Textarea";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {db} from "../../firebase";
import {doc, getDoc, serverTimestamp, setDoc} from "firebase/firestore";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonThird from "shared/Button/ButtonThird";
import {Address} from "types/address";
import {uid} from 'uid';
import {checkObject, deleteUndefinedFields} from "utils/validation";
import Checkbox from "../../shared/Checkbox/Checkbox";
import CardAddress from "../../components/CardAddress/CardAddress";
import Loader from "../../components/Loader";

const AccountAddresses = () => {
    const initialData: Address = {
        id: undefined,
        city: '',
        district: '',
        address: '',
        title: '',
        defaultAddress: undefined
    };
    const [data, setData] = useState<Address>(initialData);
    const [districts, setDistricts] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const {t} = useTranslation();
    const {userInfo} = useSelector((state: any) => state.auth);
    const docRef = doc(db, "users-addresses", userInfo.uid);
    const [showAddressForm, setShowAddressForm] = useState(false);
    const [addresses, setAddresses] = useState<Address[]>([]);
    const [addressesLoading, setAddressesLoading] = useState(false);
    useEffect(() => {
        fetchAddresses();
    }, [userInfo.uid]);

    const selectCity = (e: any) => {
        const city = e.target.value;
        const selectedCity = citiesAndDistricts.data.find((item) => item.il_adi === city);
        if (selectedCity) {
            setData({...data, city: selectedCity.il_adi});
            setDistricts(selectedCity.ilceler);
        } else {
            setDistricts([]);
        }
    };

    const handleInput = (e: any) => {
        const id = e.target?.id;
        const value = e.target?.value!;
        setData({...data, [id]: value});
    };

    const validateForm = () => {
        try {
            checkObject(data, ['id', 'defaultAddress']);
            return true;
        } catch (error: any) {
            toast.error(t('mustBeEntered').replace('#', t(error.message)),
                {position: "top-center", id: "nc-product-notify", duration: 5000});
            return false;
        }
    };

    const saveAddress = async () => {
        const id = uid();
        let addressesTemp = addresses;
        if (validateForm()) {
            setLoading(true);
            let tempData = deleteUndefinedFields(data);
            if (tempData.defaultAddress) {
                const defaultAddressIndex = addressesTemp.findIndex(i => i.defaultAddress);
                addressesTemp[defaultAddressIndex].defaultAddress = false;
            }
            if (tempData.id) {
                addressesTemp = addresses.filter((item: any) => item.id !== tempData.id);
                addressesTemp.push({...tempData});
            } else {
                addressesTemp.push({...tempData, id});
            }
            await setDoc(doc(db, "users-addresses", userInfo.uid)
                , {addresses: addressesTemp, timeStamp: serverTimestamp()})
                .then(() => {
                    toast.success(t('addressAdded'), {position: "top-center", id: "nc-product-notify", duration: 3000});
                    fetchAddresses();
                    setData(initialData);
                    setLoading(false);
                    setShowAddressForm(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    };

    const deleteAddress = async (id: string) => {
        setDeleteLoading(true);
        let addressesTemp = addresses.filter((item: any) => item.id !== id);
        await setDoc(doc(db, "users-addresses", userInfo.uid)
            , {addresses: addressesTemp, timeStamp: serverTimestamp(),})
            .then(() => {
                toast.success(t('addressAdded'), {position: "top-center", id: "nc-product-notify", duration: 3000});
                fetchAddresses();
                setData(initialData);
                setDeleteLoading(false);
                setShowAddressForm(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchAddresses = () => {
        setAddressesLoading(true);
        getDoc(docRef)
            .then(docSnap => {
                if (docSnap.exists()) {
                    const addressesDoc = docSnap.data();
                    setAddresses(addressesDoc.addresses);
                } else {
                    console.log("No such document!");
                }
            }).finally(() => setAddressesLoading(false));
    };

    const setAddressForUpdate = (address: any) => {
        setData(address);
        const selectedCity = citiesAndDistricts.data.find((item) => item.il_adi === address.city);
        if (selectedCity) {
            setDistricts(selectedCity.ilceler);
        }
        setShowAddressForm(true);
    };

    const addressForm = () => {
        return (
            <div className="space-y-10 sm:space-y-12">
                <h2 className="text-2xl sm:text-3xl font-semibold">{t('myAddresses')}</h2>
                <div className="grid grid-cols-2 grid-rows-1 md:grid-cols-4 lg:grid-cols-2">
                    <div className="tile row-start-1 row-end-2 col-span-1 md:col-span-2 lg:col-span-1 mr-2">
                        <Label>{t('city')}</Label>
                        <Select className="mt-1.5" id="city" value={data.city ?? ''} onChange={selectCity}>
                            <option value="">{t('chooseCity')}</option>
                            {citiesAndDistricts.data.map((city) => (
                                <option key={city.plaka_kodu} value={city.il_adi}>{city.il_adi}</option>
                            ))}
                        </Select>
                    </div>
                    <div className="tile row-start-1 row-end-2 col-span-1 md:col-span-2 lg:col-span-1">
                        <Label>{t('district')}</Label>
                        <Select className="mt-1.5" id="district" value={data.district ?? ''} onChange={handleInput}
                                disabled={districts.length === 0}>
                            <option value="">{t('chooseDistrict')}</option>
                            {districts.map((district: any) => (
                                <option key={district.ilce_kodu} value={district.ilce_adi}>{district.ilce_adi}</option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div>
                    <Label>{t('address')}</Label>
                    <Textarea className="mt-1.5" id="address" maxLength={255} value={data.address ?? ''}
                              onChange={handleInput}/>
                </div>
                <div>
                    <Label>{t('addressTitle')}</Label>
                    <Input className="mt-1.5" id="title" maxLength={25} value={data.title ?? ''}
                           onChange={handleInput}/>
                </div>
                <div>
                    <Checkbox
                        className="ml-2"
                        name="defaultAddress"
                        label={t('makeDefault')}
                        defaultChecked={data.defaultAddress}
                        onChange={(value: boolean) => handleInput({target: {id: 'defaultAddress', value: value}})}
                    />
                </div>
                <div className="pt-2">
                    <ButtonPrimary loading={loading} onClick={saveAddress} disabled={loading}>
                        {t('save')}
                    </ButtonPrimary>
                    <ButtonSecondary onClick={() => {
                        setShowAddressForm(false);
                        setData(initialData);
                    }} className="ml-2 bg-gray-200">
                        {t('cancel')}
                    </ButtonSecondary>
                    {(addresses.length > 1 && data.id) &&
                        <ButtonThird loading={deleteLoading} onClick={() => deleteAddress(data.id!)}
                                     className="ml-2 bg-red-200">
                            {t('delete')}
                        </ButtonThird>}
                </div>
            </div>
        );
    };

    const renderAddresses = () => {
        return (addressesLoading ? <Loader/> : <>
            <ButtonPrimary onClick={() => setShowAddressForm(true)}>{t('addAddress')}</ButtonPrimary>
            <div className="grid lg:grid-cols-3 gap-5 xl:gap-8 mt-auto">
                {addresses.map((address: any) => (
                    <CardAddress key={address.id} address={address} showButton={true} buttonText={t('edit')}
                                 onClickButton={() => setAddressForUpdate(address)}/>
                ))}
            </div>
        </>);
    };

    return (
        <div>
            <Helmet>
                <title>{t('myAddresses')} | {process.env.REACT_APP_NAME}</title>
            </Helmet>
            <CommonLayout>
                {showAddressForm ? addressForm() : renderAddresses()}
            </CommonLayout>

        </div>
    );
};

export default AccountAddresses;
