import {Product} from "../types/product";

export const addDecimals = (num: number) => {
    return (Math.round(num * 100) / 100).toFixed(2);
};

export const updateCart = (state: any, calculatePrice: boolean = true) => {
    if (calculatePrice) {
        state.itemPrice = addDecimals(state.cartItems.reduce((acc: number, item: Product) => acc + item.price * item.qty!, 0));
        if (state.cartItems.length) {
            state.shippingPrice = addDecimals(state.itemPrice > 500 ? 0 : 29.99);
        } else {
            state.shippingPrice = addDecimals(0);
        }
        //state.taxPrice = addDecimals(Number((0.15 * state.itemPrice).toFixed(2)));
        state.totalPrice = (Number(state.itemPrice) + Number(state.shippingPrice)).toFixed(2);
    }

    localStorage.setItem('cart', JSON.stringify(state));
}