import "./design-page.scss";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import tshirt from './basic-t-shirt.png';
//import {Image} from "fabric/fabric-impl";
import {fabric} from "fabric";
import Label from "../../components/Label/Label";
import Select from "../../shared/Select/Select";
import {useTranslation} from "react-i18next";
import Button from "../../shared/Button/Button";
import {Canvas, Gradient, Pattern} from "fabric/fabric-impl";

const colors = [
    {color: 'white', value: '#fff'},
    {color: 'black', value: '#000'},
    {color: 'red', value: '#f00'},
    {color: 'green', value: '#008000'},
    {color: 'yellow', value: '#ff0'}
];

const templateImages = [
    {
        name: 'Polat Alemdar',
        url: 'https://firebasestorage.googleapis.com/v0/b/kips-camkilos.appspot.com/o/template-images%2Fpolat-alemdar.jpeg?alt=media&token=50e86de3-7acf-4950-94af-7a38ef769d6f'
    },
    {
        name: 'Batman',
        url: 'https://firebasestorage.googleapis.com/v0/b/kips-camkilos.appspot.com/o/template-images%2Fbatman.png?alt=media&token=ba63d45b-d38e-4e19-9c0e-7984b57f0e1b'
    },
    {
        name: 'Superman',
        url: 'https://firebasestorage.googleapis.com/v0/b/kips-camkilos.appspot.com/o/template-images%2Fsuperman.png?alt=media&token=91def659-7e46-4a64-8d6c-66001f29c9f5'
    }
];

function PageDesignYourself() {
    const {fabric} = require('fabric');
    const canvasRef = useRef(null);
    const [canvas, setCanvas] = useState<Canvas | null>(null);
    const [imageURL, setImageURL] = useState('');
    const {t} = useTranslation();

    useEffect(() => {
        if (canvasRef) {
            console.log(canvasRef);
            setCanvas(new fabric.Canvas(canvasRef.current));
        }
    }, [canvasRef]);

    useEffect(() => {
        if (canvas) {
            canvas.clear();
            const updateTshirtImage = (imageURL: string) => {
                fabric.Image.fromURL(imageURL, function (img: fabric.Image) {
                    img.scaleToHeight(300);
                    img.scaleToWidth(300);
                    canvas.centerObject(img);
                    canvas.add(img);
                    canvas.renderAll();
                });
            }
            updateTshirtImage(imageURL);
            // Clean up
            return () => {
                canvas.dispose();
            };
        }
    }, [imageURL]);


    function onChangeTShirtColor(event: ChangeEvent<HTMLSelectElement>) {
        let tshirtDiv = document.getElementById("tshirt-div");
        if (tshirtDiv) {
            tshirtDiv.style.backgroundColor = event.target.value;
        }
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        let reader = new FileReader();
        if (canvas) {
            canvas.clear();
            reader.onload = function (event) {
                let imgObj = new Image();
                imgObj.src = event.target?.result as string;
                // When the picture loads, create the image in Fabric.js
                imgObj.onload = function () {
                    let img = new fabric.Image(imgObj);
                    img.scaleToHeight(300);
                    img.scaleToWidth(300);
                    canvas.centerObject(img);
                    canvas.add(img);
                    canvas.renderAll();
                    canvas.height = 400;
                    canvas.width = 200;
                };
                return () => {
                    canvas.dispose();
                };
            };
        }

        if (e) {
            if (e.target) {
                if (e.target.files) {
                    reader.readAsDataURL(e.target.files[0]);
                }
            }
        }

    };

    return (
        <main className="container relative z-10 mt-9 sm:mt-11 flex ">
            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-10 lg:pr-14 lg:space-y-14">
                <div id="tshirt-div">

                    <img id="tshirt-backgroundpicture"
                         src={tshirt} alt="t-shirt"/>

                    <div id="drawingArea" className="drawing-area">
                        <div className="canvas-container">
                            <canvas id="tshirt-canvas" ref={canvasRef} width="100" height="200"></canvas>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex-grow">
                <h1 className="mb-5"> Dilediğin Renk ve Görselle Kendin Tasarla!</h1>
                <div className="hidden lg:block sticky top-28">
                    <Label>Hazır Görseller</Label>
                    <Select id="tshirt-design" onChange={event => setImageURL(event.target.value)}>
                        <option value="">Hazır Tasarım Seçiniz</option>
                        {templateImages.map(img => (<option key={img.url} value={img.url}>{img.name}</option>))}
                    </Select>

                    <div>
                        <Label>{t('color')}</Label>
                        <Select className="mt-1.5" id="tshirt-color" onChange={onChangeTShirtColor}>
                            {colors.map(col => (
                                <option key={col.value} value={col.value}>{t(col.color)}</option>
                            ))}
                        </Select>
                    </div>
                    <br/>
                    <Label>Upload your own design:</Label>
                    <input type="file" id="tshirt-custompicture" onChange={handleFileChange}/>
                    <Button onClick={() => {
                        console.log(canvas)
                        canvas?.clear();
                    }}>Temizle</Button>
                </div>
            </div>
        </main>);
}

export default PageDesignYourself;