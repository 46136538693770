import {Address} from "../../types/address";
import React, {FC} from "react";
import ButtonSecondary from "../../shared/Button/ButtonSecondary";

export interface CardAddressProps {
    address: Address;
    showButton: boolean;
    buttonText: string;
    onClickButton: () => void;
}

const CardAddress: FC<CardAddressProps> = ({address, showButton, buttonText, onClickButton}) => {
    return (<div key={address.id}
                 className="h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden border-neutral-200 dark:border-neutral-700 mt-2"
    >
        <div className="mb-8">
            <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
                {address.title} {address.defaultAddress ? '✅' : ''}
            </h3>
        </div>
        <nav className="space-y-4 mb-8">
            <li className="flex items-center">
                                <span className="text-neutral-700 dark:text-neutral-300">
                                    {address.address.length > 50 ? address.address.substring(0, 50) + '...' : address.address}
                                </span>

            </li>
            <li className="flex items-center">
                                <span className="text-neutral-700 dark:text-neutral-300">
                                    {address.district + ' / ' + address.city}
                                </span>
            </li>
        </nav>
        {showButton && <div className="flex flex-col mt-auto">
            <ButtonSecondary onClick={onClickButton}>
                <span className="font-medium">{buttonText}</span>
            </ButtonSecondary>
        </div>}
    </div>);
}

export default CardAddress;