import React, {FC, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import SidebarFilters from "./SidebarFilters";
import {useTranslation} from "react-i18next";
import {Product} from "../types/product";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {db} from "../firebase";
import ProductCard from "../components/ProductCard";
import Loader from "../components/Loader";
import {useLocation} from "react-router-dom";

export interface PageCollectionProps {
    className?: string;
}

const PageCollection: FC<PageCollectionProps> = ({className = ""}) => {
    const {t} = useTranslation();
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState(false);

    const queryRoute = useQuery();

    function useQuery() {
        const {search} = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    useEffect(() => {
        fetchProducts({categories: [], colors: [], sizes: [], priceRange: [], sortOrder: "", gender: []});
    }, [queryRoute]);

    const fetchProducts = async (filterParams: any) => {
        setLoading(true);
        let productsTemp: Product[] = [];
        const productsRef = collection(db, "products");
        const queryConstraints = [];
        if (queryRoute.get("gender")) {
            queryConstraints.push(where("gender", "==", queryRoute.get("gender")));
        }
        if (filterParams.categories?.length > 0 || queryRoute.get("category")) {
            queryConstraints.push(where("category", "in", [queryRoute.get("category"), ...filterParams.categories]));
        }
        if (filterParams.colors?.length > 0) {
            queryConstraints.push(where("color", "in", filterParams.colors));
        }
        if (filterParams.sizes?.length > 0) {
            queryConstraints.push(where("sizes", "array-contains-any", filterParams.sizes));
        }
        if (filterParams.priceRange?.length > 0) {
            queryConstraints.push(where("price", ">=", filterParams.priceRange[0]));
            queryConstraints.push(where("price", "<=", filterParams.priceRange[1]));
        }
        if (filterParams.sortOrder) {
            switch (filterParams.sortOrder) {
                case "price-low-to-high":
                    queryConstraints.push(orderBy("price", "asc"));
                    break;
                case "price-high-to-low":
                    queryConstraints.push(orderBy("price", "desc"));
                    break;
                case "newest":
                    queryConstraints.push(orderBy("timeStamp", "desc"));
                    break;
                default:
                    break;
            }
        }
        const querySnapshot = await getDocs(query(productsRef, ...queryConstraints));
        querySnapshot.forEach((doc) => {
            const product = doc.data() as Product;
            productsTemp.push(product);
        });
        setProducts(productsTemp);
        setLoading(false);
    };

    const renderProducts = () => {
        if (loading) return (<Loader/>);

        if (products.length === 0) return (<div className="text-center">{t('productNotFound')}</div>);

        return (<div className="flex-1 grid sm:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-10 ">
            {products.map((item) => (
                <ProductCard data={item} key={item.uid}/>
            ))}
        </div>);
    }

    return (
        <div
            className={`nc-PageCollection2 ${className}`}
            data-nc-id="PageCollection2"
        >
            <Helmet>
                <title>{t('category')} | {process.env.REACT_APP_NAME}</title>
            </Helmet>

            <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
                <div className="space-y-10 lg:space-y-14">
                    <main>
                        <div className="flex flex-col lg:flex-row">
                            <div className="lg:w-1/3 xl:w-1/4 pr-4">
                                <SidebarFilters fetchProducts={fetchProducts} minPrice={100}
                                                selectedCategory={queryRoute.get("category")}
                                                maxPrice={500}/>
                            </div>
                            <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 border-t lg:border-t-0"></div>
                            <div className="flex-1 ">
                                {renderProducts()}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default PageCollection;
