const checkField = (field: string, value: string) => {
    if (value === '' || !value) {
        throw new Error(field);
    }
};

const stringIsEmpty = (value: string) => {
    return value === '' || !value;
}

const checkObject = (object: any, nonRequiredFields: string[]) => {
    console.log(object);
    Object.keys(object)
        .filter((k) => !nonRequiredFields.includes(k))
        .forEach((key) => {
            if (object[key] === '' || !object[key]) {
                throw new Error(key);
            }
        });
};

function deleteUndefinedFields(obj: any): any {
    const newObj: any = {};
    for (const key of Object.keys(obj)) {
        if (undefined !== obj[key]) {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}

export {checkField, checkObject, stringIsEmpty, deleteUndefinedFields};
