import Label from "components/Label/Label";
import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { auth, updatePassword } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

const AccountPass = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    password: "",
    newPassword: "",
    confirmPassword: ""
  });
  const { t } = useTranslation();

  const handleInput = (e: any) => {
    const id = e.target?.id;
    const value = e.target?.value!;

    setData({ ...data, [id]: value });
  };

  const passwordUpdate = async () => {
    setLoading(true);
    if (data.newPassword !== data.confirmPassword) {
      toast.error(t('passwordsNotMatches'), { position: "top-center", id: "nc-product-notify", duration: 3000 });
    } else {
      signInWithEmailAndPassword(auth, auth.currentUser!.email!, data.password)
        .then(async (_userCredential) => {
          // Signed in
          updatePassword(auth.currentUser!, data.newPassword).then(() => {
            toast.success(t('passwordUpdated'), { position: "top-center", id: "nc-product-notify", duration: 3000 });
            setLoading(false);
            setData({
              password: "",
              newPassword: "",
              confirmPassword: ""
            })
          }).catch((error) => {
            console.log(error);
            setLoading(false);
          });
        })
        .catch((error) => {
          toast.error(t('currentPasswordIncorrect'), { position: "top-center", id: "nc-product-notify", duration: 3000 });
          setLoading(false);
        });
    }
  };
  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            {t('passwordUpdate')}
          </h2>
          <div className=" max-w-xl space-y-6">
            <div>
              <Label>{t('currentPassword')}</Label>
              <Input type="password" id="password" value={data.password} onChange={handleInput} className="mt-1.5" />
            </div>
            <div>
              <Label>{t('currentPassword')}</Label>
              <Input type="password" id="newPassword" value={data.newPassword} onChange={handleInput} className="mt-1.5" />
            </div>
            <div>
              <Label>{t('newPassword')}</Label>
              <Input type="password" id="confirmPassword" value={data.confirmPassword} onChange={handleInput} className="mt-1.5" />
            </div>
            <div className="pt-2">
              <ButtonPrimary loading={loading} onClick={passwordUpdate}>
                {t('update')}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
