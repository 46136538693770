import React, {ChangeEvent, InputHTMLAttributes} from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    sizeClass?: string;
    fontClass?: string;
    rounded?: string;
    isCardNumber?: boolean;
    maxLength?: number;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            className = "",
            sizeClass = "h-11 px-4 py-3",
            fontClass = "text-sm font-normal",
            rounded = "rounded-2xl",
            children,
            type = "text",
            isCardNumber = false,
            maxLength = undefined,
            ...args
        },
        ref
    ) => {
        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            let formattedValue = event.target.value;

            // Handle credit card number formatting (if isCardNumber is true)
            if (isCardNumber) {
                formattedValue = formattedValue
                    .replace(/\D/g, '') // Remove non-digits
                    .replace(/([0-9]{4})/g, '$1 ') // Add spaces after every 4 digits
                    .trim(); // Remove trailing spaces
            }

            // Enforce maxLength (if provided)
            if (maxLength && formattedValue.length > maxLength) {
                formattedValue = formattedValue.slice(0, maxLength);
            }

            event.target.value = formattedValue;
            args.onChange && args.onChange(event); // Call original onChange handler
        };

        return (
            <input
                ref={ref}
                type={type}
                onChange={handleChange}
                className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 ${rounded} ${fontClass} ${sizeClass} ${className}`}
                {...args}
            />
        );
    }
);

export default Input;
