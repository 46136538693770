import { FC, useState } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, updateProfile } from "../../firebase";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {checkObject} from "../../utils/validation";
export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();


  const handleLogin = async (e: any) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      toast.error(t('passwordsNotMatches'), { position: "top-center", id: "nc-product-notify", duration: 3000 });
    } else {
      setLoading(true);
      try{
        checkObject({name,surname,email,password}, []);
        createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
              // Signed in
              const savedUser = userCredential.user;
              updateProfile(savedUser, {
                displayName: name + " " + surname
              }).then(() => {
                toast.success(t('accountCreated'), {position: "top-center", id: "nc-product-notify", duration: 3000});
                setLoading(false);
                navigate("/login");
              }).catch((error) => {
                console.log(error);
                setLoading(false);
              });
              //dispatch({type:"LOGIN", payload:user})
            })
            .catch((error) => {
              console.log(error.code);
              if (error.code === 'auth/email-already-in-use') {
                toast.error(t('emailAlreadyInUse'), {position: "top-center", id: "nc-product-notify", duration: 3000});
              }
              if (error.code === 'auth/weak-password') {
                toast.error(t('passwordAtLeast6Characters'), {
                  position: "top-center",
                  id: "nc-product-notify",
                  duration: 3000
                });
              }
              setLoading(false);
            });
      }catch (error : any) {
        toast.error(t('mustBeEntered').replace('#', t(error.message)),
            { position: "top-center", id: "nc-product-notify", duration: 5000 });
        setLoading(false);
      }
    }
  };

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>{t('signUp')} | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h6 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t('signUp')}
        </h6>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleLogin} autoComplete="off">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('name')}
              </span>
              <Input
                type="text"
                placeholder={t('name')}
                className="mt-1"
                onChange={(e) => setName(e.target.value)}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('surname')}
              </span>
              <Input
                type="text"
                placeholder={t('surname')}
                className="mt-1"
                onChange={(e) => setSurname(e.target.value)}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('email')}
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t('password')}
              </span>
              <Input type="password"
                placeholder="*********"
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1" />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t('passwordConfirm')}
              </span>
              <Input type="password"
                placeholder="*********"
                onChange={(e) => setPasswordConfirm(e.target.value)}
                className="mt-1" />
            </label>
            <ButtonPrimary loading={loading} type="submit">{t('signUp')}</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {`${t('alreadyHaveAnAccount')} `}
            <Link className="text-green-600" to="/login">
              {t('signIn')}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
