import React, {useEffect, useState} from "react";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import {useTranslation} from "react-i18next";
import {collection, getDocs, limit, orderBy, query} from "firebase/firestore";
import {db} from "../../firebase";
import {Product} from "../../types/product";
import Loader from "../../components/Loader";

function PageHome() {
    const [loading, setLoading] = useState<boolean>(true);
    const [products, setProducts] = useState<Product[]>([]);
    const {t} = useTranslation();
    const fetchData = async () => {
        setLoading(true);
        let productsTemp: Product[] = [];
        const productsRef = collection(db, "products");
        const q = query(productsRef, orderBy("timeStamp"), limit(6));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            productsTemp.push(doc.data() as Product);
        });
        setProducts(productsTemp);
        setLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div className="nc-PageHome relative overflow-hidden">
            <SectionHero2/>

            <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
                {/* SECTION */}
                {
                    loading ? <Loader/> : <SectionSliderProductCard
                        heading={t("newArrivals")}
                        data={products}
                    />
                }

                <div className="py-24 lg:py-32 border-t border-b border-slate-200 dark:border-slate-700">
                    <SectionHowItWork/>
                </div>

                <SectionSliderCategories/>
                {
                    loading ?
                        <Loader/> :
                        <SectionSliderProductCard
                            heading={t("bestSellers")}
                            subHeading={t("bestSellingOfTheMonth")}
                            data={products}
                        />
                }

            </div>
        </div>
    );
}

export default PageHome;
