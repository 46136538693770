import {createSlice} from '@reduxjs/toolkit';
import {updateCart} from '../utils/cartUtils';
import {Product} from "../types/product";

const initialState = localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart') ?? '')
    : {cartItems: [], shippingAddress: {}, paymentMethod: 'Credit Card', contactInfo: {}};


const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToContactInfo: (state, action) => {
            state.contactInfo = action.payload;
            return updateCart(state, false);
        },
        addToShippingAddress: (state, action) => {
            state.shippingAddress = action.payload;
            return updateCart(state, false);
        },
        addToCart: (state, action) => {
            const item = action.payload;
            const existItem = state.cartItems.find((x: Product) => x.uid === item.uid);
            if (existItem) {
                state.cartItems = state.cartItems.map((x: Product) => x.uid === existItem.uid ? item : x);
            } else {
                state.cartItems = [...state.cartItems, item];
            }
            return updateCart(state, true);
        },
        updateQuantity: (state, action) => {
            const payload = action.payload;
            const existItem = state.cartItems.find((x: Product) => x.uid === payload.uid);
            if (existItem) {
                state.cartItems = state.cartItems.map((x: Product) => x.uid === existItem.uid ? {
                    ...x,
                    qty: payload.qty
                } : x);
            }
            return updateCart(state, true);
        },
        removeFromCart: (state, action) => {
            state.cartItems = state.cartItems.filter((x: Product) => x.uid !== action.payload);
            return updateCart(state, true);
        },
        saveShippingAddress: (state, action) => {
            state.shippingAddress = action.payload;
            return updateCart(state, true);
        }
    }
});

export const {
    addToCart, removeFromCart,
    updateQuantity, addToShippingAddress,
    addToContactInfo
} = cartSlice.actions;

export default cartSlice.reducer;